<template>
  <div class="connect">
    <div class="main">
      <img class="left" src="@/assets/imgs/connect.png">
      <div class="right">
        <img class="logo" src="@/assets/imgs/logo.png">
        <div class="title">Embark On A<br>Journey Of Discovery<br>With My World: Super App</div>
        <div class="web">Your Web 3.0 Super App</div>
        <div class="btn" @click="router.push('/')">Connect</div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router'

  const router = useRouter()
</script>

<style lang="less" scoped>
.connect{
  height: 100vh;
  background: url("~@/assets/imgs/bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .main{
    width: 1200px;
    display: flex;
    align-items: center;
    padding-right: 42px;
    justify-content: space-between;

    .left{
      width: 506px;
    }
    .right{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;

      .logo{
        width: 86px;
        margin-bottom: 70px;
      }
      .title{
        font-size: 44px;
        line-height: 52px;
        margin-bottom: 26px;
      }
      .web{
        font-size: 22px;
        color: #697377;
        margin-bottom: 70px;
      }
      .btn{
        width: 272px;
        height: 42px;
        border-radius: 10px;
        background: #06C8A1;
        font-size: 16px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: "DM Sans Bold";
      }
    }
  }
}
</style>
